import React, { useState, useEffect } from "react";
import { Card, Divider, Button, Descriptions, Modal, Select } from "antd";
import { useParams, useNavigate } from "react-router-dom";
import { generateClient } from "aws-amplify/api";
import { toast, ToastContainer } from "react-toastify";
import { getSwap, getBattery } from "../../../../graphql/queries";
import { deleteSwap, deleteBattery, updateSwap } from "../../../../graphql/mutations";
import { fetchAuthSession, fetchUserAttributes } from 'aws-amplify/auth';

const { Option } = Select;
const client = generateClient();

const DetailedSwap = () => {
  const { swapID } = useParams();
  const navigate = useNavigate();
  const [swap, setSwap] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [updatedStatus, setUpdatedStatus] = useState("");

  useEffect(() => {
    const fetchSwapDetails = async () => {
      try {
        const { data } = await client.graphql({
          query: getSwap,
          variables: { id: swapID },
        });
        setSwap(data.getSwap);
        setUpdatedStatus(data.getSwap.status);
      } catch (error) {
        console.error("Error fetching battery details:", error);
      }
    };

    fetchSwapDetails();
  }, [swapID]);

  useEffect(() => {
    checkUserRole();
  }, [client]);

  async function checkUserRole() {
    try {
      const session = await fetchAuthSession();
      const userAttributes = await fetchUserAttributes();

      const isAdminUser = userAttributes['custom:isAdmin'] === 'true';

      const groups = session.tokens.accessToken.payload['cognito:groups'];

      const isInAdminGroup = groups && groups.includes('Admins');
      const isInOperatorsGroup = groups && groups.includes('Operators');

      setIsAdmin(isAdminUser || isInAdminGroup);

    } catch (error) {
      console.error("Error checking user role:", error);
    }
  }

  const handleUpdateSwapStatus = async () => {
    try {
      await client.graphql({
        query: updateSwap,
        variables: { input: { id: swapID, status: updatedStatus } },
      });
      toast.success("Swap status updated successfully");
      setSwap({ ...swap, status: updatedStatus });
      setIsModalVisible(false);
    } catch (error) {
      toast.error("Error updating swap status");
      console.error("Error updating swap status:", error);
    }
  };

  if (!swap) {
    return <div>Loading...</div>;
  }

  const handleDelete = async () => {
    try {
      // if (swap.batteryInNumber) {
      //   const batteryResponse = await client.graphql({
      //     query: getBattery,
      //     variables: { number: swap.batteryInNumber },
      //   });
      //   const batteryId = batteryResponse.data.getBatteryByNumber.items[0]?.id;

      //   if (batteryId) {
      //     await client.graphql({
      //       query: deleteBattery,
      //       variables: { input: { id: batteryId } },
      //     });
      //   }
      // }
      await client.graphql({
        query: deleteSwap,
        variables: { input: { id: swapID } },
      });
      toast.success("Swap and battery deleted successfully");
      navigate('/swap');
    } catch (error) {
      toast.error("Error deleting swap");
      console.error("Error deleting swap!", error);
    }
  };

  if (!swap) {
    return <div>Loading...</div>;
  }

  return (
    <Card title={`Swap Details`} style={{ margin: 20 }}>
      <Descriptions bordered column={{ lg: 1, md: 1, sm: 1 }}>
        <Descriptions.Item label="Battery In Number">{swap.batteryInNumber}</Descriptions.Item>
        <Descriptions.Item label="Battery Out Number">{swap.batteryOutNumber}</Descriptions.Item>
        <Descriptions.Item label="Bike Type">{swap.bike}</Descriptions.Item>
        <Descriptions.Item label="Rider Name">{swap.riderName}</Descriptions.Item>
        <Descriptions.Item label="Rider Phone Nummber">{swap.riderPhoneNumber}</Descriptions.Item>
        <Descriptions.Item label="Number of Orders">{swap.numberOfOrders}</Descriptions.Item>
        <Descriptions.Item label="Operator Name">{swap.operatorName}</Descriptions.Item>
        <Descriptions.Item label="Platform">{swap.platform}</Descriptions.Item>
        <Descriptions.Item label="Franchise">{swap.franchise}</Descriptions.Item>
        <Descriptions.Item label="Amount">{swap.amount}</Descriptions.Item>
        <Descriptions.Item label="Status">{swap.status}</Descriptions.Item>
        <Descriptions.Item label="Reference">{swap.reference}</Descriptions.Item>
      </Descriptions>
      <Divider />
      <div style={{ display: "flex", justifyContent: "space-around", padding: 20, gap: 50 }}>
        {isAdmin && (
          <Button block type="danger" size="large" onClick={handleDelete}>
            Delete Swap
          </Button>
        )}
        <Button block type="primary" size="large" onClick={() => setIsModalVisible(true)}>
          Update Swap
        </Button>
        <ToastContainer />
      </div>
      <Modal
        title="Update Swap Status"
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onOk={handleUpdateSwapStatus}
      >
        <Select
          value={updatedStatus}
          onChange={(value) => setUpdatedStatus(value)}
          style={{ width: "100%" }}
        >
          <Option value="cancelled">Cancelled</Option>
          <Option value="success">Success</Option>
          <Option value="failed">Failed</Option>
        </Select>
      </Modal>
    </Card>
  );
};

export default DetailedSwap;
