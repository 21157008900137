import React, { useEffect, useState } from "react";
import { Form, Input, Button, Select, Divider } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { generateClient } from 'aws-amplify/api';
import { createRider, updateRider } from "../../../../graphql/mutations";
import { getRider, listFleets, listFranchises, listRiders } from "../../../../graphql/queries";
import { fetchAuthSession, fetchUserAttributes } from '@aws-amplify/auth';
import ExcelUploader from "../../../../components/upload";
import * as XLSX from 'xlsx';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

const { Option } = Select;

const filterOption = (input, option) =>
    (option?.children ?? '').toLowerCase().includes(input.toLowerCase());

const RiderForm = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const { riderID } = useParams();
    const client = generateClient();
    const [franchises, setFranchises] = useState([]);
    const [fleets, setFleets] = useState([]);
    const [isEditMode, setIsEditMode] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [phoneNumber, setPhoneNumber] = useState('');


    useEffect(() => {
        const fetchData = async () => {
            const franchisesResponse = await client.graphql({ query: listFranchises });
            setFranchises(franchisesResponse.data.listFranchises.items || []);

            const fleetsResponse = await client.graphql({ query: listFleets })
            setFleets(fleetsResponse.data.listFleets.items || []);

            if (riderID) {
                setIsEditMode(true);
                const { data } = await client.graphql({
                    query: getRider,
                    variables: { id: riderID },
                });
                const riderData = data.getRider;
                form.setFieldsValue({
                    name: riderData.name,
                    phone_number: riderData.phone_number,
                    nationalIDNumber: riderData.nationalIDNumber,
                    vehicleType: riderData.vehicleType,
                    fleet: riderData.fleet,
                    franchise: riderData.franchise,
                    status: riderData.status,
                });
            }
        };

        fetchData();
    }, [riderID, form]);

    useEffect(() => {
        checkUserRole();
    }, []);

    async function checkUserRole() {
        try {
            const session = await fetchAuthSession();
            const userAttributes = await fetchUserAttributes();

            const isAdminUser = userAttributes['custom:isAdmin'] === 'true';

            const groups = session.tokens.accessToken.payload['cognito:groups'];

            const isInAdminGroup = groups && groups.includes('Admins');

            setIsAdmin(isAdminUser || isInAdminGroup);
        } catch (error) {
            console.error("Error checking user role:", error);
        }
    }

    const validatePhoneNumber = (_, value) => {
        const phoneRegex = /^\+254[17]\d{8}$/;
        if (!value || phoneRegex.test(value)) {
            return Promise.resolve();
        }
        return Promise.reject(new Error('Please enter a valid Kenyan phone number (+254...)'));
    };

    const handleFileUpload = (file) => {
        const reader = new FileReader();
        reader.onload = async (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const json = XLSX.utils.sheet_to_json(worksheet);

            console.log(`Total riders in Excel: ${json.length}`);

            if (json.length === 0) {
                toast.error("The Excel file is empty or could not be parsed.");
                return;
            }

            const requiredColumns = ['Rider Name', 'Phone number', 'ID Number', 'Vehicle Type', 'Fleet', 'Location'];
            const headers = Object.keys(json[0]);
            console.log("Excel headers:", headers);

            const headerMap = {};
            requiredColumns.forEach(required => {
                const match = headers.find(header =>
                    header.toLowerCase().trim() === required.toLowerCase().trim()
                );
                if (match) {
                    headerMap[required] = match;
                }
            });

            const missingColumns = requiredColumns.filter(col => !headerMap[col]);

            if (missingColumns.length > 0) {
                toast.error(`Missing required columns: ${missingColumns.join(', ')}`);
                return;
            }

            try {
                let processedCount = 0;
                let skippedCount = 0;
                let duplicateCount = 0;
                let errorCount = 0;

                for (let i = 0; i < json.length; i++) {
                    const row = json[i];
                    console.log(`Processing row ${i + 1}:`, row);

                    const riderData = {
                        name: row[headerMap['Rider Name']]?.trim(),
                        phone_number: row[headerMap['Phone number']]?.toString(),
                        nationalIDNumber: row[headerMap['ID Number']]?.toString(),
                        vehicleType: row[headerMap['Vehicle Type']],
                        fleet: row[headerMap['Fleet']]?.trim(),
                        franchise: row[headerMap['Location']],
                        status: 'approved',
                    };

                    if (!riderData.phone_number || !riderData.name || !riderData.nationalIDNumber) {
                        console.error(`Row ${i + 1}: Missing required fields:`, riderData);
                        skippedCount++;
                        continue;
                    }

                    try {
                        const existingRidersResponse = await client.graphql({
                            query: listRiders,
                            variables: { filter: { phone_number: { eq: riderData.phone_number } } }
                        });

                        const existingRiders = existingRidersResponse.data.listRiders.items;

                        if (existingRiders.length === 0) {
                            await client.graphql({
                                query: createRider,
                                variables: {
                                    input: riderData
                                },
                            });
                            processedCount++;
                            console.log(`Row ${i + 1}: Rider added successfully:`, riderData);
                        } else {
                            console.warn(`Row ${i + 1}: Rider with number ${riderData.phone_number} already exists. Skipping.`);
                            duplicateCount++;
                        }
                    } catch (error) {
                        console.error(`Row ${i + 1}: Error processing rider:`, error);
                        errorCount++;
                    }

                    setUploadProgress(Math.round(((i + 1) / json.length) * 100));
                }

                console.log(`Upload Summary:
                    Total in Excel: ${json.length}
                    Processed: ${processedCount}
                    Skipped (Invalid): ${skippedCount}
                    Duplicates: ${duplicateCount}
                    Errors: ${errorCount}`
                );

                toast.success(`Riders uploaded. 
                    Added: ${processedCount}, 
                    Skipped (Invalid): ${skippedCount}, 
                    Duplicates: ${duplicateCount}, 
                    Errors: ${errorCount}`
                );
                navigate("/riders");
            } catch (error) {
                console.error("Error uploading riders:", error);
                toast.error("An error occurred while uploading riders");
            } finally {
                setUploadProgress(0);
            }
        };
        reader.readAsArrayBuffer(file);
    };

    const handleSubmit = async (values) => {
        try {

            if (!isEditMode) {
                const existingRidersResponse = await client.graphql({
                    query: listRiders,
                    variables: { filter: { phone_number: { eq: values.phone_number } } }
                });

                const existingRiders = existingRidersResponse.data.listRiders.items;

                if (existingRiders.length > 0) {
                    toast.error("A rider with this phone number already exists. Please use a different number.");
                    return;
                }
            }

            const riderData = {
                name: values.name,
                phone_number: values.phone_number,
                nationalIDNumber: values.nationalIDNumber,
                vehicleType: values.vehicleType,
                fleet: values.fleet,
                franchise: values.franchise,
                status: isEditMode ? values.status : 'approved',
            };

            if (isEditMode) {
                riderData.id = riderID;
                await client.graphql({
                    query: updateRider,
                    variables: { input: riderData }
                });
                toast.success("Rider updated successfully");
            } else {
                await client.graphql({
                    query: createRider,
                    variables: { input: riderData },
                });
                toast.success("Rider added successfully");
            }
            navigate("/riders");
        }
        catch (error) {
            console.error(error);
            toast.error("An error occurred while processing the rider");
        }
    }

    const headingText = isEditMode ? "Update the Rider Below:" : "Add a Rider Below:";
    const buttonText = isEditMode ? "Update Rider" : "Add Rider";

    return (
        <div style={{ display: "flex", flexDirection: "column", marginTop: 20, gap: 10, alignItems: "center", height: "100vh" }}>
            <h3>{headingText}</h3>
            <Form
                form={form}
                name="riderForm"
                onFinish={handleSubmit}
                style={{ width: "40%" }}
            >
                <Form.Item
                    name="name"
                    rules={[{ required: true, message: "Please input the rider name!" }]}
                >
                    <Input placeholder="Rider Name" disabled={isEditMode && !isAdmin} />
                </Form.Item>
                <Form.Item
                    name="phone_number"
                    rules={[{ required: true, message: "Please input the phone number!" }]}
                >
                    <PhoneInput
                        placeholder="Rider phone number"
                        value={phoneNumber}
                        onChange={setPhoneNumber}
                        defaultCountry="KE"
                        style={{ width: '100%' }}
                    />
                </Form.Item>
                <Form.Item
                    name="nationalIDNumber"
                    rules={[{ required: true, message: "Please input the rider's ID number!" }]}
                >
                    <Input placeholder="Rider ID Number" />
                </Form.Item>
                <Form.Item
                    name="vehicleType"
                    rules={[{ required: true, message: "Please select the vehicle type!" }]}
                >
                    <Select placeholder="Vehicle Type" disabled={isEditMode && !isAdmin}>
                        <Option value="Shujaa">Shujaa</Option>
                        <Option value="Shujaa2">Shujaa 2.0</Option>
                        <Option value="Jasiri">Jasiri</Option>
                        <Option value="Moka">Moka</Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    name="franchise"
                    rules={[{ required: true, message: "Please select the rider's franchise!" }]}
                >
                    <Select placeholder="Franchise Location" disabled={isEditMode && !isAdmin}>
                        {franchises.length > 0 && franchises.map((franchise) => (
                            <Option key={franchise.id} value={franchise.location}>
                                {franchise.location}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    name="fleet"
                    rules={[{ required: true, message: "Please select the rider fleet!" }]}
                >
                    <Select
                        placeholder="Fleet"
                        showSearch
                        filterOption={filterOption}
                    >
                        {fleets.length > 0 && fleets.map((fleet) => (
                            <Option key={fleet.id} value={fleet.name}>
                                {fleet.name}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
                {isEditMode && isAdmin && (
                    <Form.Item
                        name="status"
                        rules={[{ required: true, message: "Please select the rider status!" }]}
                    >
                        <Select placeholder="Rider Status">
                            <Option value="pending">Pending</Option>
                            <Option value="approved">Approved</Option>
                            <Option value="suspended">Suspended</Option>
                        </Select>
                    </Form.Item>
                )}
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        {buttonText}
                    </Button>
                </Form.Item>
            </Form>
            {!isEditMode && isAdmin && (
                <>
                    <Divider>Or</Divider>
                    <div>
                        <h3>Upload Riders</h3>
                        <ExcelUploader onUpload={handleFileUpload} uploadProgress={uploadProgress} />
                    </div>
                </>
            )}
        </div>
    );
};

export default RiderForm;