import React, { useState, useEffect } from 'react';
import { Form, Input, Select, Button, InputNumber, Modal, Radio } from "antd";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { generateClient } from 'aws-amplify/api';
import axios from 'axios';
import { updateBattery, createSwap, updateSwap } from "../../graphql/mutations";
import { listOperators, listFranchises, listBatteries, listRiders, getSwap, listFleets } from '../../graphql/queries';

const { Option } = Select;

const filterOption = (input, option) =>
    (option?.children ?? '').toLowerCase().includes(input.toLowerCase());

const AddSwapModal = ({ visible, onCancel, swapID, onClose }) => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const client = generateClient();
    const isEditMode = Boolean(swapID);
    const location = useLocation();
    const swap = location.state?.swap;
    const [isLoading, setIsLoading] = useState(false);
    const [operators, setOperators] = useState([]);
    const [batteries, setBatteries] = useState([]);
    const [fleets, setFleets] = useState([]);
    const [allBatteries, setAllBatteries] = useState([]);
    const [availableBatteriesIn, setAvailableBatteriesIn] = useState([]);
    const [availableBatteriesOut, setAvailableBatteriesOut] = useState([]);
    const [selectedFranchise, setSelectedFranchise] = useState(null);
    const [selectedBikeType, setSelectedBikeType] = useState(null);
    const [selectedPlatform, setSelectedPlatform] = useState(null);
    const [allFranchises, setAllFranchises] = useState([]);
    const [filteredFranchises, setFilteredFranchises] = useState([]);
    const [riders, setRiders] = useState([]);
    const [amount, setAmount] = useState(null);
    const [selectedCountry, setSelectedCountry] = useState("Kenya");

    const handleBikeTypeChange = (value) => {
        setSelectedBikeType(value);

        // Only update amount if country is Kenya
        if (selectedCountry === "Kenya") {
            if (value === "Jasiri") {
                setAmount(170);
            } else if (value === "Shujaa" || value === "Shujaa2") {
                setAmount(60);
            } else {
                setAmount(null);
            }
        }
        // If country is Rwanda, amount stays at 500
    };

    const handleCountryChange = (e) => {
        const country = e.target.value;
        setSelectedCountry(country);

        if (country === "Rwanda") {
            setAmount(500);
        } else {
            // If switching to Kenya, set amount based on current bike type
            if (selectedBikeType) {
                if (selectedBikeType === "Jasiri") {
                    setAmount(170);
                } else if (selectedBikeType === "Shujaa" || selectedBikeType === "Shujaa2") {
                    setAmount(60);
                } else {
                    setAmount(null);
                }
            } else {
                setAmount(null);
            }
        }
    };

    useEffect(() => {
        if (swapID) {
            const fetchSwapDetails = async () => {
                try {
                    const { data } = await client.graphql({
                        query: getSwap,
                        variables: { id: swapID },
                    });
                    form.setFieldsValue(data.getSwap);
                } catch (error) {
                    console.error("Error fetching swap details:", error);
                }
            };
            fetchSwapDetails();
        }
    }, [swapID]);

    //Franchise useEffects
    // Fetch all franchises
    useEffect(() => {
        const fetchFranchises = async () => {
            try {
                const response = await client.graphql({ query: listFranchises });
                const franchisesList = response.data.listFranchises.items || [];
                setAllFranchises(franchisesList);
                setFilteredFranchises(franchisesList.filter(franchise => franchise.country === selectedCountry));
            } catch (error) {
                console.error("Error fetching franchises:", error);
            }
        };

        fetchFranchises();
    }, [selectedCountry]);

    // Filter franchises when the selected country changes
    useEffect(() => {
        setFilteredFranchises(allFranchises.filter(franchise => franchise.country === selectedCountry));
    }, [selectedCountry, allFranchises]);

    //Batteries useEffects
    // Fetch batteries for "batteryIn" selection
    useEffect(() => {
        const fetchAvailableBatteriesIn = async () => {
            if (selectedBikeType) {
                let allAvailableBatteries = [];
                let nextToken = null;
                do {
                    const batteriesResponse = await client.graphql({
                        query: listBatteries,
                        variables: {
                            filter: {
                                and: [
                                    { status: { eq: "in use" } },
                                    { bike: { eq: selectedBikeType } },
                                ]
                            },
                            limit: 1000,
                            nextToken: nextToken
                        }
                    });
                    const fetchedBatteries = batteriesResponse.data.listBatteries.items || [];
                    allAvailableBatteries = [...allAvailableBatteries, ...fetchedBatteries];
                    nextToken = batteriesResponse.data.listBatteries.nextToken;
                } while (nextToken);

                setAvailableBatteriesIn(allAvailableBatteries);
            }
        };

        fetchAvailableBatteriesIn();
    }, [selectedBikeType]);

    // Fetch batteries for "batteryOut" selection
    useEffect(() => {
        const fetchAvailableBatteriesOut = async () => {
            if (selectedBikeType) {
                let allAvailableBatteries = [];
                let nextToken = null;
                do {
                    const batteriesResponse = await client.graphql({
                        query: listBatteries,
                        variables: {
                            filter: {
                                and: [
                                    { status: { eq: "charged" } },
                                    { bike: { eq: selectedBikeType } }
                                ]
                            },
                            limit: 1000,
                            nextToken: nextToken
                        }
                    });
                    const fetchedBatteries = batteriesResponse.data.listBatteries.items || [];
                    allAvailableBatteries = [...allAvailableBatteries, ...fetchedBatteries];
                    nextToken = batteriesResponse.data.listBatteries.nextToken;
                } while (nextToken);

                setAvailableBatteriesOut(allAvailableBatteries);
            }
        };

        fetchAvailableBatteriesOut();
    }, [selectedBikeType]);

    const filteredBatteriesIn = allBatteries.filter(battery => battery.bike === selectedBikeType);

    //Operator useEffect
    //fetch operators in selected franchise
    useEffect(() => {
        const fetchOperators = async () => {
            if (selectedFranchise) {
                const operatorsResponse = await client.graphql({
                    query: listOperators,
                    variables: { filter: { franchise: { eq: selectedFranchise } } }
                });
                setOperators(operatorsResponse.data.listOperators.items || []);
            }
        };

        fetchOperators();
    }, [selectedFranchise]);

    //Riders useEffect
    //fetch riders in selected franchise and platform
    useEffect(() => {
        const fetchAllRiders = async () => {
            try {
                let allRiders = [];
                let nextToken = null;
                let filter = {};
                if (selectedPlatform) {
                    filter.fleet = { eq: selectedPlatform };
                }
                if (selectedBikeType) {
                    filter.vehicleType = { eq: selectedBikeType };
                }

                console.log("Fetching riders with filter:", JSON.stringify(filter, null, 2));

                do {
                    const ridersResponse = await client.graphql({
                        query: listRiders,
                        variables: {
                            filter: filter,
                            limit: 1000,
                            nextToken: nextToken
                        }
                    });

                    const fetchedRiders = ridersResponse.data.listRiders.items || [];
                    allRiders = [...allRiders, ...fetchedRiders];
                    nextToken = ridersResponse.data.listRiders.nextToken;

                    console.log(`Fetched ${fetchedRiders.length} riders. Total: ${allRiders.length}`);
                } while (nextToken);

                console.log("Total riders fetched:", allRiders.length);
                setRiders(allRiders);
            } catch (error) {
                console.error('Error fetching riders:', error);
                toast.error("Failed to fetch riders");
            }
        };

        fetchAllRiders();
    }, [selectedPlatform, selectedBikeType]);

    const handleRiderChange = async (riderId) => {
        const selectedRider = riders.find(rider => rider.id === riderId);
        if (selectedRider) {
            form.setFieldsValue({
                riderName: selectedRider.name,
                riderPhoneNumber: selectedRider.phone_number
            });
        }
    };

    useEffect(() => {
        const fetchFleets = async () => {
            const fleetsResposne = await client.graphql({ query: listFleets });
            setFleets(fleetsResposne.data.listFleets.items || []);
        }

        fetchFleets();
    }, []);
    // Number starts with 254
    const formatPhoneNumber = (phoneNumber) => {
        return phoneNumber.replace(/\D/g, '').replace(/^(?:\+?254|0)(\d{9})$/, '254$1');
    };

    // Amount useEffect
    useEffect(() => {
        if (amount !== null) {
            form.setFieldsValue({ amount });
        }
    }, [amount, form]);

    const handleSubmit = async (values) => {
        try {
            setIsLoading(true);

            const batteryIn = availableBatteriesIn.find(b => b.number === values.batteryIn);
            const batteryOut = availableBatteriesOut.find(b => b.number === values.batteryOut);

            if (!batteryIn || !batteryOut) {
                throw new Error("Selected batteries not found");
            }

            const swapAmount = parseInt(values.amount);
            const reference = `swap-${Date.now()}`;

            // Prepare swap data
            const swapData = {
                country: selectedCountry,
                franchise: values.franchise,
                bike: values.bike,
                batteryInNumber: values.batteryIn,
                batteryOutNumber: values.batteryOut,
                operatorName: values.operatorName,
                platform: values.platform,
                numberOfOrders: values.numberOfOrders,
                riderName: values.riderName,
                riderPhoneNumber: values.riderPhoneNumber,
                amount: swapAmount,
                status: "pending",
                reference: reference
            };

            // Create pending swap first
            const pendingSwap = await client.graphql({
                query: createSwap,
                variables: { input: swapData }
            });

            const swapId = pendingSwap.data.createSwap.id;

            // Only process STK push for Kenya
            if (selectedCountry === "Kenya") {
                // Initiate STK push
                const stkPushData = {
                    balanceId: 1102631,
                    amount: swapAmount,
                    phoneNumber: formatPhoneNumber(values.riderPhoneNumber),
                    reference: reference,
                };

                try {
                    const stkPushResponse = await axios.post(
                        'https://mpesa-api5-bb4478673c3c.herokuapp.com/stk-push',
                        stkPushData
                    );

                    const { success, requestId, details } = stkPushResponse.data;
                    const extractedReference = details.match(/Reference is: (\S+)/)?.[1] || requestId;

                    if (success) {
                        // Update both batteries and swap status after successful payment
                        await Promise.all([
                            // Update incoming battery (being swapped in)
                            client.graphql({
                                query: updateBattery,
                                variables: {
                                    input: {
                                        id: batteryIn.id,
                                        status: "charged",
                                        franchise: values.franchise,
                                        currentLocation: values.franchise
                                    }
                                }
                            }),
                            // Update outgoing battery (being swapped out)
                            client.graphql({
                                query: updateBattery,
                                variables: {
                                    input: {
                                        id: batteryOut.id,
                                        status: "in use",
                                        franchise: values.franchise,
                                        currentLocation: values.riderName
                                    }
                                }
                            }),
                            // Update swap status to success
                            client.graphql({
                                query: updateSwap,
                                variables: {
                                    input: {
                                        id: swapId,
                                        status: "success",
                                        reference: extractedReference
                                    }
                                }
                            })
                        ]);

                        toast.success("Payment successful and swap completed!");
                        form.resetFields();
                        onCancel();
                        navigate("/swap");
                    } else {
                        await client.graphql({
                            query: updateSwap,
                            variables: {
                                input: {
                                    id: swapId,
                                    status: "failed",
                                    reference: reference
                                }
                            }
                        });
                        toast.error(details || "Payment failed");
                    }
                } catch (error) {
                    console.error('Payment processing error:', error);
                    await client.graphql({
                        query: updateSwap,
                        variables: {
                            input: {
                                id: swapId,
                                status: "failed",
                                reference: reference
                            }
                        }
                    });

                    const errorMessage = error.response?.status === 400 ?
                        "Payment request failed. Please try again." :
                        "An error occurred while processing payment";
                    toast.error(errorMessage);
                }
            } else {
                // For Rwanda, directly update batteries without STK push
                await Promise.all([
                    // Update incoming battery
                    client.graphql({
                        query: updateBattery,
                        variables: {
                            input: {
                                id: batteryIn.id,
                                status: "charged",
                                franchise: values.franchise,
                                currentLocation: values.franchise
                            }
                        }
                    }),
                    // Update outgoing battery
                    client.graphql({
                        query: updateBattery,
                        variables: {
                            input: {
                                id: batteryOut.id,
                                status: "in use",
                                franchise: values.franchise,
                                currentLocation: values.riderName
                            }
                        }
                    }),
                    // Update swap status to success
                    client.graphql({
                        query: updateSwap,
                        variables: {
                            input: {
                                id: swapId,
                                status: "success",
                                reference: reference
                            }
                        }
                    })
                ]);

                toast.success("Swap completed successfully!");
                form.resetFields();
                onClose();
                navigate("/swap", { state: { refreshData: true } });
            }
        } catch (error) {
            console.error("Error processing swap:", error);
            toast.error(error.message || "An error occurred while processing the swap");
        } finally {
            setIsLoading(false);
        }
    };

    // Reset form values when modal opens
    useEffect(() => {
        if (visible) {
            console.log('Modal opened - Resetting form');
            form.resetFields();
            setAmount(null);
            setSelectedBikeType(null);
            setSelectedCountry("Kenya");
        }
    }, [visible, form]);

    return (
        <Modal
            title="Add Swap"
            visible={visible}
            onCancel={onClose}
            footer={null}
        >
            <Form form={form} layout="vertical" onFinish={handleSubmit}>

                {/* Country Selection */}
                <Form.Item label="Select Country">
                    <Radio.Group
                        onChange={handleCountryChange}
                        value={selectedCountry}
                    >
                        <Radio value="Kenya">Kenya</Radio>
                        <Radio value="Rwanda">Rwanda</Radio>
                    </Radio.Group>
                </Form.Item>

                {/* Franchise Location*/}
                <Form.Item
                    label="Franchise"
                    name="franchise"
                    rules={[{ required: true, message: "Please select the franchise location!" }]}
                >
                    <Select
                        placeholder="Franchise Location"
                        onChange={(value) => setSelectedFranchise(value)}
                        showSearch
                        filterOption={filterOption}
                    >
                        {filteredFranchises.map((franchise) => (
                            <Option key={franchise.id} value={franchise.location}>
                                {franchise.location}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>

                {/* Bike Type*/}
                <Form.Item label="Bike Type" name="bike" rules={[{ required: true }]}>
                    <Select
                        placeholder="Bike Type"
                        onChange={handleBikeTypeChange}
                        showSearch
                        filterOption={filterOption}
                    >
                        <Option value="Shujaa">Shujaa</Option>
                        <Option value="Shujaa2">Shujaa 2.0</Option>
                        <Option value="Jasiri">Jasiri</Option>
                    </Select>
                </Form.Item>

                {/* Battery In */}
                <Form.Item label="Battery In" name="batteryIn" rules={[{ required: true }]}>
                    <Select
                        placeholder="Battery In Number"
                        showSearch
                        filterOption={filterOption}
                        disabled={!selectedBikeType}
                    >
                        {availableBatteriesIn.map((battery) => (
                            <Option key={battery.id} value={battery.number}>
                                {battery.number}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>

                {/* Battery Out */}
                <Form.Item label="Battery Out" name="batteryOut" rules={[{ required: true }]}>
                    <Select
                        placeholder="Battery Out Number"
                        disabled={!selectedBikeType}
                        showSearch
                        filterOption={filterOption}
                    >
                        {availableBatteriesOut.map((battery) => (
                            <Option key={battery.id} value={battery.number}>
                                {battery.number}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>

                {/* Operator Name */}
                <Form.Item
                    label="Operator Name"
                    name="operatorName"
                    rules={[{ required: true, message: "Please enter the operator's name!" }]}
                >
                    <Select placeholder="Operator Name"
                        disabled={!selectedFranchise}
                        showSearch
                        filterOption={filterOption}
                    >
                        {operators.map((operator) => (
                            <Option key={operator.id} value={operator.name}>
                                {operator.name}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>

                {/* Platform */}
                <Form.Item
                    label="Platform"
                    name="platform"
                    rules={[{ required: true, message: "Please input the rider platform!" }]}
                >
                    <Select
                        placeholder="Platform"
                        onChange={(value) => setSelectedPlatform(value)}
                        showSearch
                        filterOption={filterOption}
                    >
                        {fleets.length > 0 && fleets.map((fleet) => (
                            <Option key={fleet.id} value={fleet.name}>
                                {fleet.name}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>

                {/* Number of Orders */}
                <Form.Item
                    label="Number of Orders"
                    name="numberOfOrders"
                    rules={[
                        { required: true, message: "Please enter the number of orders!" },
                        { type: 'number', min: 0, message: "Number of orders must be a positive number!" }
                    ]}
                >
                    <InputNumber placeholder="Number of Orders" style={{ width: '100%' }} />
                </Form.Item>

                {/* Rider Name */}
                <Form.Item
                    label="Rider"
                    name="riderName"
                    rules={[{ required: true, message: "Please select a rider!" }]}
                >
                    <Select
                        placeholder="Select Rider"
                        onChange={handleRiderChange}
                        disabled={!selectedPlatform || !selectedFranchise || !selectedBikeType}
                        showSearch
                        filterOption={filterOption}
                    >
                        {riders.map((rider) => (
                            <Option key={rider.id} value={rider.id}>
                                {rider.name}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>

                {/* Rider Phone Number */}
                <Form.Item
                    name="riderPhoneNumber"
                    rules={[
                        { required: true, message: "Please input the phone number!" },
                    ]}
                >
                    <Input
                        placeholder="Rider Phone Number"
                        disabled
                    />
                </Form.Item>

                {/*Amount */}
                <Form.Item
                    label="Amount"
                    name="amount"
                    rules={[{ required: true, message: "Please enter the amount to be paid!" }]}
                >
                    <Input
                        placeholder="Amount"
                        disabled={true}
                    />
                </Form.Item>

                <Form.Item>
                    <div style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
                        <Button type="primary" htmlType="submit" loading={isLoading}>
                            Confirm Swap
                        </Button>
                        <Button onClick={() => form.resetFields()} type="default" style={{ marginLeft: 8 }}>
                            Clear Form
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default AddSwapModal;