import React, { useState, useEffect } from "react";
import { Card, Table, Tag, Button, Tooltip, DatePicker, Space, Modal } from "antd";
import { listSwaps } from "../../../graphql/queries";
import { createSwap } from "../../../graphql/mutations"
import { generateClient } from "aws-amplify/api";
import { useNavigate, useLocation } from "react-router-dom";
import downloadExcel from "../../../components/download";
import { DownloadOutlined } from '@ant-design/icons';
import { toast } from "react-toastify";
import SearchComponent from "../../../components/searchBar";
import { fetchAuthSession, fetchUserAttributes } from 'aws-amplify/auth';
import AddSwapModal from "../../../components/swap";
import ExcelUploader from "../../../components/upload";
import * as XLSX from "xlsx";

const client = generateClient();
const { RangePicker } = DatePicker;

const Swap = () => {
  const [swaps, setSwaps] = useState([]);
  const [filteredSwaps, setFilteredSwaps] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [dateRange, setDateRange] = useState([null, null]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [riderNames, setRiderNames] = useState([]);
  const [franchises, setFranchises] = useState([]);
  const [platforms, setPlatforms] = useState([]);
  //Add swap modal
  const [isModalVisible, setIsModalVisible] = useState(false);
  //Bulk Upload modal
  const [isUploadModalVisible, setIsUploadModalVisible] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleOpen = () => {
    setIsModalVisible(true);
  }

  const handleClose = () => {
    setIsModalVisible(false);
  }

  const handleSubmit = (data) => {
    console.log('Swap details:', data);
    handleClose();
  };

  const renderSwapStatus = (requestStatus) => {
    if (requestStatus === "success") {
      return <Tag color={"green"}>{requestStatus}</Tag>;
    }
    if (requestStatus === "pending") {
      return <Tag color={"orange"}>{requestStatus}</Tag>;
    }
    if (requestStatus === "failed") {
      return <Tag color={"red"}>{requestStatus}</Tag>;
    }
  };


  useEffect(() => {
    if (location.state?.refreshData) {
      fetchSwaps();
      window.history.replaceState({}, document.title)
    }
  }, [location]);

  useEffect(() => {
    fetchSwaps();
    checkUserRole();
  }, [client]);

  useEffect(() => {
    filterSwaps();
  }, [swaps, searchTerm, dateRange, selectedFilter]);

  async function checkUserRole() {
    try {
      const session = await fetchAuthSession();
      const userAttributes = await fetchUserAttributes();

      const isAdminUser = userAttributes['custom:isAdmin'] === 'true';

      const groups = session.tokens.accessToken.payload['cognito:groups'];

      const isInAdminGroup = groups && groups.includes('Admins');
      const isInOperatorsGroup = groups && groups.includes('Operators');

      setIsAdmin(isAdminUser || isInAdminGroup);

    } catch (error) {
      console.error("Error checking user role:", error);
    }
  }

  async function fetchSwaps() {
    try {
      const swapData = await client.graphql({ query: listSwaps });
      const formattedSwaps = swapData.data.listSwaps.items;
      const sortedSwaps = formattedSwaps.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      setSwaps(sortedSwaps);
      setFilteredSwaps(sortedSwaps);
      setRiderNames([...new Set(sortedSwaps.map(swap => swap.riderName).filter(Boolean))]);
      setFranchises([...new Set(sortedSwaps.map(swap => swap.franchise).filter(Boolean))]);
      setPlatforms([...new Set(sortedSwaps.map(swap => swap.platform).filter(Boolean))]);
    } catch (error) {
      console.error("Error fetching swaps:", error);
    }
  }

  const handleSearch = (term) => {
    setSearchTerm(term);
  };

  const handleDateRangeChange = (dates) => {
    setDateRange(dates);
  };

  const filterSwaps = () => {
    let filtered = swaps;

    if (searchTerm) {
      filtered = filtered.filter(swap =>
        swap.batteryInNumber.toLowerCase().includes(searchTerm.toLowerCase()) ||
        swap.batteryOutNumber.toLowerCase().includes(searchTerm.toLowerCase()) ||
        swap.franchise.toLowerCase().includes(searchTerm.toLowerCase()) ||
        swap.riderName.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    if (dateRange && dateRange[0] && dateRange[1]) {
      filtered = filtered.filter(swap => {
        const swapDate = new Date(swap.createdAt);
        return swapDate >= dateRange[0].startOf('day') && swapDate <= dateRange[1].endOf('day');
      });
    }

    if (selectedFilter) {
      filtered = filtered.filter(swap =>
        swap.riderName === selectedFilter ||
        swap.franchise === selectedFilter ||
        swap.platform === selectedFilter
      );
    }

    filtered = filtered.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    setFilteredSwaps(filtered);
  }

  const handleFileUpload = (file) => {

    setIsProcessing(true);
    const reader = new FileReader();
    reader.onload = async (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const json = XLSX.utils.sheet_to_json(worksheet);

      console.log(`Total swaps in Excel: ${json.length}`);

      if (json.length === 0) {
        toast.error("The Excel file is empty or could not be parsed.");
        setIsProcessing(false);
        return;
      }

      const requiredColumns = ['Battery In Number', 'Battery Out Number', 'Bike Type', 'Rider Name', 'Phone number', 'Operator Name', 'Platform', 'Franchise', 'Number of Orders', 'Amount'];
      const headers = Object.keys(json[0]);

      const headerMap = {};
      requiredColumns.forEach(required => {
        const match = headers.find(header =>
          header.toLowerCase().trim() === required.toLowerCase().trim()
        );
        if (match) {
          headerMap[required] = match;
        }
      });

      const missingColumns = requiredColumns.filter(col => !headerMap[col]);

      if (missingColumns.length > 0) {
        toast.error(`Missing required columns: ${missingColumns.join(', ')}`);
        setIsProcessing(false);
        return;
      }

      try {
        let processedCount = 0;
        let errorCount = 0;

        for (let i = 0; i < json.length; i++) {
          const row = json[i];

          const swapData = {
            batteryInNumber: String(row[headerMap['Battery In Number']] || '').trim(),
            batteryOutNumber: String(row[headerMap['Battery Out Number']] || '').trim(),
            bike: String(row[headerMap['Bike Type']] || '').trim(),
            riderName: String(row[headerMap['Rider Name']] || '').trim(),
            riderPhoneNumber: String(row[headerMap['Phone number']] || '').trim(),
            operatorName: String(row[headerMap['Operator Name']] || '').trim(),
            platform: String(row[headerMap['Platform']] || '').trim(),
            franchise: String(row[headerMap['Franchise']] || '').trim(),
            numberOfOrders: Number(row[headerMap['Number of Orders']]) || 0,
            amount: Number(row[headerMap['Amount']]) || 0,
            status: "success",
            reference: `SWAP-${Date.now()}-${i}`,
          };

          if (!swapData.batteryInNumber ||
            !swapData.batteryOutNumber ||
            !swapData.riderName ||
            !swapData.riderPhoneNumber ||
            !swapData.operatorName ||
            !swapData.platform ||
            !swapData.franchise) {
            console.error(`Row ${i + 1}: Missing required fields:`, swapData);
            errorCount++;
            continue;
          }
          try {
            await client.graphql({
              query: createSwap,
              variables: {
                input: swapData
              },
            });
            processedCount++;
            console.log(`Row ${i + 1}: Swap added successfully:`, swapData);
          } catch (error) {
            console.error(`Row ${i + 1}: Error processing dispatch:`, error);
            errorCount++;
          }

          setUploadProgress(Math.round(((i + 1) / json.length) * 100));
        }

        console.log(`Upload Summary:
                      Total in Excel: ${json.length}
                      Processed: ${processedCount}
                      Errors: ${errorCount}`
        );

        toast.success(`Swaps uploaded. 
                      Added: ${processedCount}, 
                      Errors: ${errorCount}`
        );
        setIsUploadModalVisible(false);
        setUploadedFile(null);
        fetchSwaps();
      } catch (error) {
        console.error("Error uploading dispatches:", error);
        toast.error("An error occurred while uploading dispatches");
      } finally {
        setIsProcessing(false);
        setUploadProgress(0);
      }
    };
    reader.readAsArrayBuffer(file);
  };


  const tableColumns = [
    {
      title: "Battery In",
      dataIndex: "batteryInNumber",
      key: "batteryInNumber",
    },
    {
      title: "Battery Out",
      dataIndex: "batteryOutNumber",
      key: "batteryOutNumber",
    },
    {
      title: "Rider",
      dataIndex: "riderName",
      key: "riderName",
    },
    {
      title: "Franchise",
      dataIndex: "franchise",
      key: "franchise",
    },
    {
      title: "Platform",
      dataIndex: "platform",
      key: "platform",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (amount, record) => {
        const currencyPrefix = record.country === "Rwanda" ? "R₣" : "Ksh ";
        return `${currencyPrefix}${amount}`;
      }
    },
    {
      title: "Reference",
      dataIndex: "reference",
      key: "reference",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: renderSwapStatus,
    },
    {
      title: "Date Created",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => new Date(text).toLocaleString(),
      sorter: (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
      defaultSortOrder: 'ascend',
    },
  ];

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: 20,
          padding: 20,
        }}
      >
        <h2>Swaps</h2>
        <SearchComponent
          placeholder="Search by battery number"
          onSearch={handleSearch}
        />
        <div
          style={{
            display: "flex",
            gap: 20,
          }}>
          <Button type="primary" onClick={() => setIsUploadModalVisible(true)}>
            Bulk Upload
          </Button>
          <Button type="primary" onClick={() => setIsModalVisible(true)}>
            Add Swap
          </Button>
        </div>

        <AddSwapModal visible={isModalVisible} onClose={handleClose} onSubmit={handleSubmit} />
      </div>
      <Card title="Swaps List" extra={
        <Space>
          <RangePicker onChange={handleDateRangeChange} />
          {isAdmin && (
            <Tooltip title="Download as Excel">
              <DownloadOutlined
                onClick={() => downloadExcel(filteredSwaps, "swaps")}
                style={{ fontSize: '20px', cursor: 'pointer' }}
              />
            </Tooltip>
          )}
        </Space>
      }
        style={{ margin: 20 }}
      >
        <Table dataSource={filteredSwaps} columns={tableColumns} rowKey="id"
          onRow={(requestItem) => ({
            onClick: () => {
              navigate(`/swap/${requestItem.id}`);
            },
          })} />
      </Card>
      <Modal
        title="Upload Swaps"
        open={isUploadModalVisible}
        onCancel={() => {
          setIsUploadModalVisible(false)
          setUploadedFile(null)
        }}
        footer={null}
        centered
      >
        <ExcelUploader
          onUpload={handleFileUpload}
          uploadProgress={uploadProgress}
        />
        <div style={{ display: "flex", justifyContent: "center", marginTop: 20 }}>
          <Button type="primary"
            onClick={handleFileUpload}
            loading={isProcessing}
            disabled={!uploadedFile || isProcessing}
          >
            Add Swaps
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default Swap;