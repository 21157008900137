import React, { useState, useEffect, useCallback } from 'react';
import { Card, Row, Col, Input, DatePicker, Select, Table, Typography, Button, Spin, message } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { generateClient } from 'aws-amplify/api';
import { listBikes } from '../../../graphql/queries'; 
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';

const { Title } = Typography;
const { RangePicker } = DatePicker;
const { Option } = Select;

const GOOGLE_MAPS_API_KEY = 'AIzaSyB_-k4D94cQAAM8yrXHbVtH06uWujEuMnY';

const mapContainerStyle = {
  width: '100%',
  height: '400px'
};

const defaultCenter = {
  lat: -1.2921,
  lng: 36.8219 // Default to Nairobi, Kenya
};

const Tracking = () => {
  const [bikes, setBikes] = useState([]);
  const [filteredBikes, setFilteredBikes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [dateRange, setDateRange] = useState([null, null]);
  const [statusFilter, setStatusFilter] = useState('all');
  const [mapCenter, setMapCenter] = useState(defaultCenter);

  const client = generateClient();

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GOOGLE_MAPS_API_KEY
  });

  const fetchBikes = useCallback(async () => {
    setLoading(true);
    try {
      const bikesData = await client.graphql({ query: listBikes });
      const bikeItems = bikesData.data.listBikes.items;
      setBikes(bikeItems);
      setFilteredBikes(bikeItems);
    } catch (error) {
      console.error('Error fetching bikes:', error);
      message.error('Failed to fetch bikes. Please try again.');
    }
    setLoading(false);
  }, [client]);

  useEffect(() => {
    fetchBikes();
    // Get user's current location
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setMapCenter({ lat: latitude, lng: longitude });
        },
        () => {
          message.info('Unable to get your location. Using default location.');
        }
      );
    }
  }, []); // Empty dependency array means this effect runs once on mount

  useEffect(() => {
    const filtered = bikes.filter(bike => {
      const matchesSearch = !searchTerm || 
        (bike.number && bike.number.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (bike.id && bike.id.toLowerCase().includes(searchTerm.toLowerCase()));

      const matchesDateRange = !dateRange[0] || !dateRange[1] || 
        (new Date(bike.updatedAt) >= dateRange[0] && new Date(bike.updatedAt) <= dateRange[1]);

      const matchesStatus = statusFilter === 'all' || bike.status === statusFilter;

      return matchesSearch && matchesDateRange && matchesStatus;
    });

    setFilteredBikes(filtered);
  }, [bikes, searchTerm, dateRange, statusFilter]);

  const columns = [
    {
      title: 'Bike Number',
      dataIndex: 'number',
      key: 'number',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Last Updated',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (text) => text ? new Date(text).toLocaleString() : 'N/A',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Button onClick={() => handleViewOnMap(record)}>View on Map</Button>
      ),
    },
  ];

  const handleViewOnMap = (bike) => {
    if (bike.latitude && bike.longitude) {
      setMapCenter({ lat: bike.latitude, lng: bike.longitude });
    }
  };

  const onMapLoad = useCallback((map) => {
    // The map is already centered on the user's location or the default location
    // You can add any additional initialization here if needed
  }, []);

  return (
    <div style={{ padding: '24px' }}>
      <Title level={2}>Bike Tracking</Title>
      <Card style={{ marginBottom: '24px' }}>
        <Row gutter={16}>
          <Col span={8}>
            <Input
              placeholder="Search by Bike Number"
              prefix={<SearchOutlined />}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </Col>
          <Col span={8}>
            <RangePicker
              style={{ width: '100%' }}
              onChange={(dates) => setDateRange(dates)}
            />
          </Col>
          <Col span={8}>
            <Select
              style={{ width: '100%' }}
              placeholder="Filter by Status"
              onChange={(value) => setStatusFilter(value)}
              defaultValue="all"
            >
              <Option value="all">All Statuses</Option>
              <Option value="in use">In Use</Option>
              <Option value="available">Available</Option>
            </Select>
          </Col>
        </Row>
      </Card>

      <Row gutter={24}>
        <Col span={12}>
          <Card style={{ marginBottom: '24px', height: '400px' }}>
            {!isLoaded ? (
              <Spin tip="Loading map..." />
            ) : (
              <GoogleMap
                mapContainerStyle={mapContainerStyle}
                center={mapCenter}
                zoom={10}
                onLoad={onMapLoad}
              >
                {filteredBikes.map((bike) => (
                  bike.latitude && bike.longitude && (
                    <Marker
                      key={bike.id}
                      position={{ lat: bike.latitude, lng: bike.longitude }}
                      title={`Bike ${bike.number}`}
                    />
                  )
                ))}
              </GoogleMap>
            )}
          </Card>
        </Col>
        <Col span={12}>
          <Card style={{ marginBottom: '24px', height: '400px', overflowY: 'auto' }}>
            <Table
              columns={columns}
              dataSource={filteredBikes}
              rowKey="id"
              loading={loading}
              pagination={{ pageSize: 5 }}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Tracking;
