/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createRider = /* GraphQL */ `
  mutation CreateRider(
    $input: CreateRiderInput!
    $condition: ModelRiderConditionInput
  ) {
    createRider(input: $input, condition: $condition) {
      id
      name
      sub
      lat
      lng
      email
      status
      phone_number
      vehicleType
      nationalIDNumber
      fleet
      franchise
      documents {
        nextToken
        __typename
      }
      guarantors {
        nextToken
        __typename
      }
      deliveries {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateRider = /* GraphQL */ `
  mutation UpdateRider(
    $input: UpdateRiderInput!
    $condition: ModelRiderConditionInput
  ) {
    updateRider(input: $input, condition: $condition) {
      id
      name
      sub
      lat
      lng
      email
      status
      phone_number
      vehicleType
      nationalIDNumber
      fleet
      franchise
      documents {
        nextToken
        __typename
      }
      guarantors {
        nextToken
        __typename
      }
      deliveries {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteRider = /* GraphQL */ `
  mutation DeleteRider(
    $input: DeleteRiderInput!
    $condition: ModelRiderConditionInput
  ) {
    deleteRider(input: $input, condition: $condition) {
      id
      name
      sub
      lat
      lng
      email
      status
      phone_number
      vehicleType
      nationalIDNumber
      fleet
      franchise
      documents {
        nextToken
        __typename
      }
      guarantors {
        nextToken
        __typename
      }
      deliveries {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createCustomer = /* GraphQL */ `
  mutation CreateCustomer(
    $input: CreateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    createCustomer(input: $input, condition: $condition) {
      id
      name
      email
      phone_number
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateCustomer = /* GraphQL */ `
  mutation UpdateCustomer(
    $input: UpdateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    updateCustomer(input: $input, condition: $condition) {
      id
      name
      email
      phone_number
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteCustomer = /* GraphQL */ `
  mutation DeleteCustomer(
    $input: DeleteCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    deleteCustomer(input: $input, condition: $condition) {
      id
      name
      email
      phone_number
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createSalesCustomers = /* GraphQL */ `
  mutation CreateSalesCustomers(
    $input: CreateSalesCustomersInput!
    $condition: ModelSalesCustomersConditionInput
  ) {
    createSalesCustomers(input: $input, condition: $condition) {
      id
      year
      region
      county
      town
      customerName
      customerType
      salesType
      customerIndustry
      gender
      signupDate
      location
      contactPerson
      contactNumber
      noOfBikes
      bike
      status
      Comment
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateSalesCustomers = /* GraphQL */ `
  mutation UpdateSalesCustomers(
    $input: UpdateSalesCustomersInput!
    $condition: ModelSalesCustomersConditionInput
  ) {
    updateSalesCustomers(input: $input, condition: $condition) {
      id
      year
      region
      county
      town
      customerName
      customerType
      salesType
      customerIndustry
      gender
      signupDate
      location
      contactPerson
      contactNumber
      noOfBikes
      bike
      status
      Comment
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteSalesCustomers = /* GraphQL */ `
  mutation DeleteSalesCustomers(
    $input: DeleteSalesCustomersInput!
    $condition: ModelSalesCustomersConditionInput
  ) {
    deleteSalesCustomers(input: $input, condition: $condition) {
      id
      year
      region
      county
      town
      customerName
      customerType
      salesType
      customerIndustry
      gender
      signupDate
      location
      contactPerson
      contactNumber
      noOfBikes
      bike
      status
      Comment
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createLead = /* GraphQL */ `
  mutation CreateLead(
    $input: CreateLeadInput!
    $condition: ModelLeadConditionInput
  ) {
    createLead(input: $input, condition: $condition) {
      id
      customerName
      location
      from
      contactPerson
      contactNumber
      vehicleType
      useCase
      learnAbout
      franchise
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateLead = /* GraphQL */ `
  mutation UpdateLead(
    $input: UpdateLeadInput!
    $condition: ModelLeadConditionInput
  ) {
    updateLead(input: $input, condition: $condition) {
      id
      customerName
      location
      from
      contactPerson
      contactNumber
      vehicleType
      useCase
      learnAbout
      franchise
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteLead = /* GraphQL */ `
  mutation DeleteLead(
    $input: DeleteLeadInput!
    $condition: ModelLeadConditionInput
  ) {
    deleteLead(input: $input, condition: $condition) {
      id
      customerName
      location
      from
      contactPerson
      contactNumber
      vehicleType
      useCase
      learnAbout
      franchise
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createMaintenance = /* GraphQL */ `
  mutation CreateMaintenance(
    $input: CreateMaintenanceInput!
    $condition: ModelMaintenanceConditionInput
  ) {
    createMaintenance(input: $input, condition: $condition) {
      id
      equipmentName
      maintenanceDate
      technicianName
      issueDescription
      resolutionDescription
      status
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateMaintenance = /* GraphQL */ `
  mutation UpdateMaintenance(
    $input: UpdateMaintenanceInput!
    $condition: ModelMaintenanceConditionInput
  ) {
    updateMaintenance(input: $input, condition: $condition) {
      id
      equipmentName
      maintenanceDate
      technicianName
      issueDescription
      resolutionDescription
      status
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteMaintenance = /* GraphQL */ `
  mutation DeleteMaintenance(
    $input: DeleteMaintenanceInput!
    $condition: ModelMaintenanceConditionInput
  ) {
    deleteMaintenance(input: $input, condition: $condition) {
      id
      equipmentName
      maintenanceDate
      technicianName
      issueDescription
      resolutionDescription
      status
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createBike = /* GraphQL */ `
  mutation CreateBike(
    $input: CreateBikeInput!
    $condition: ModelBikeConditionInput
  ) {
    createBike(input: $input, condition: $condition) {
      id
      chassisNumber
      bikeType
      fleet
      location
      ownership
      status
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateBike = /* GraphQL */ `
  mutation UpdateBike(
    $input: UpdateBikeInput!
    $condition: ModelBikeConditionInput
  ) {
    updateBike(input: $input, condition: $condition) {
      id
      chassisNumber
      bikeType
      fleet
      location
      ownership
      status
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteBike = /* GraphQL */ `
  mutation DeleteBike(
    $input: DeleteBikeInput!
    $condition: ModelBikeConditionInput
  ) {
    deleteBike(input: $input, condition: $condition) {
      id
      chassisNumber
      bikeType
      fleet
      location
      ownership
      status
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createClient = /* GraphQL */ `
  mutation CreateClient(
    $input: CreateClientInput!
    $condition: ModelClientConditionInput
  ) {
    createClient(input: $input, condition: $condition) {
      id
      clientName
      fleetSize
      location
      contactPerson
      status
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateClient = /* GraphQL */ `
  mutation UpdateClient(
    $input: UpdateClientInput!
    $condition: ModelClientConditionInput
  ) {
    updateClient(input: $input, condition: $condition) {
      id
      clientName
      fleetSize
      location
      contactPerson
      status
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteClient = /* GraphQL */ `
  mutation DeleteClient(
    $input: DeleteClientInput!
    $condition: ModelClientConditionInput
  ) {
    deleteClient(input: $input, condition: $condition) {
      id
      clientName
      fleetSize
      location
      contactPerson
      status
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createInventory = /* GraphQL */ `
  mutation CreateInventory(
    $input: CreateInventoryInput!
    $condition: ModelInventoryConditionInput
  ) {
    createInventory(input: $input, condition: $condition) {
      id
      itemName
      category
      quantity
      status
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateInventory = /* GraphQL */ `
  mutation UpdateInventory(
    $input: UpdateInventoryInput!
    $condition: ModelInventoryConditionInput
  ) {
    updateInventory(input: $input, condition: $condition) {
      id
      itemName
      category
      quantity
      status
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteInventory = /* GraphQL */ `
  mutation DeleteInventory(
    $input: DeleteInventoryInput!
    $condition: ModelInventoryConditionInput
  ) {
    deleteInventory(input: $input, condition: $condition) {
      id
      itemName
      category
      quantity
      status
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createOperator = /* GraphQL */ `
  mutation CreateOperator(
    $input: CreateOperatorInput!
    $condition: ModelOperatorConditionInput
  ) {
    createOperator(input: $input, condition: $condition) {
      id
      employeeIDNumber
      name
      phone_number
      email
      franchise
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateOperator = /* GraphQL */ `
  mutation UpdateOperator(
    $input: UpdateOperatorInput!
    $condition: ModelOperatorConditionInput
  ) {
    updateOperator(input: $input, condition: $condition) {
      id
      employeeIDNumber
      name
      phone_number
      email
      franchise
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteOperator = /* GraphQL */ `
  mutation DeleteOperator(
    $input: DeleteOperatorInput!
    $condition: ModelOperatorConditionInput
  ) {
    deleteOperator(input: $input, condition: $condition) {
      id
      employeeIDNumber
      name
      phone_number
      email
      franchise
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createGuarantor = /* GraphQL */ `
  mutation CreateGuarantor(
    $input: CreateGuarantorInput!
    $condition: ModelGuarantorConditionInput
  ) {
    createGuarantor(input: $input, condition: $condition) {
      id
      name
      phone_number
      nationalID
      createdAt
      updatedAt
      riderGuarantorsId
      owner
      __typename
    }
  }
`;
export const updateGuarantor = /* GraphQL */ `
  mutation UpdateGuarantor(
    $input: UpdateGuarantorInput!
    $condition: ModelGuarantorConditionInput
  ) {
    updateGuarantor(input: $input, condition: $condition) {
      id
      name
      phone_number
      nationalID
      createdAt
      updatedAt
      riderGuarantorsId
      owner
      __typename
    }
  }
`;
export const deleteGuarantor = /* GraphQL */ `
  mutation DeleteGuarantor(
    $input: DeleteGuarantorInput!
    $condition: ModelGuarantorConditionInput
  ) {
    deleteGuarantor(input: $input, condition: $condition) {
      id
      name
      phone_number
      nationalID
      createdAt
      updatedAt
      riderGuarantorsId
      owner
      __typename
    }
  }
`;
export const createBattery = /* GraphQL */ `
  mutation CreateBattery(
    $input: CreateBatteryInput!
    $condition: ModelBatteryConditionInput
  ) {
    createBattery(input: $input, condition: $condition) {
      id
      number
      bike
      status
      franchise
      batteryOwnership
      currentLocation
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateBattery = /* GraphQL */ `
  mutation UpdateBattery(
    $input: UpdateBatteryInput!
    $condition: ModelBatteryConditionInput
  ) {
    updateBattery(input: $input, condition: $condition) {
      id
      number
      bike
      status
      franchise
      batteryOwnership
      currentLocation
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteBattery = /* GraphQL */ `
  mutation DeleteBattery(
    $input: DeleteBatteryInput!
    $condition: ModelBatteryConditionInput
  ) {
    deleteBattery(input: $input, condition: $condition) {
      id
      number
      bike
      status
      franchise
      batteryOwnership
      currentLocation
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createFranchise = /* GraphQL */ `
  mutation CreateFranchise(
    $input: CreateFranchiseInput!
    $condition: ModelFranchiseConditionInput
  ) {
    createFranchise(input: $input, condition: $condition) {
      id
      location
      address
      operator
      country
      contactNumber
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateFranchise = /* GraphQL */ `
  mutation UpdateFranchise(
    $input: UpdateFranchiseInput!
    $condition: ModelFranchiseConditionInput
  ) {
    updateFranchise(input: $input, condition: $condition) {
      id
      location
      address
      operator
      country
      contactNumber
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteFranchise = /* GraphQL */ `
  mutation DeleteFranchise(
    $input: DeleteFranchiseInput!
    $condition: ModelFranchiseConditionInput
  ) {
    deleteFranchise(input: $input, condition: $condition) {
      id
      location
      address
      operator
      country
      contactNumber
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createFleet = /* GraphQL */ `
  mutation CreateFleet(
    $input: CreateFleetInput!
    $condition: ModelFleetConditionInput
  ) {
    createFleet(input: $input, condition: $condition) {
      id
      name
      country
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateFleet = /* GraphQL */ `
  mutation UpdateFleet(
    $input: UpdateFleetInput!
    $condition: ModelFleetConditionInput
  ) {
    updateFleet(input: $input, condition: $condition) {
      id
      name
      country
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteFleet = /* GraphQL */ `
  mutation DeleteFleet(
    $input: DeleteFleetInput!
    $condition: ModelFleetConditionInput
  ) {
    deleteFleet(input: $input, condition: $condition) {
      id
      name
      country
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createDispatch = /* GraphQL */ `
  mutation CreateDispatch(
    $input: CreateDispatchInput!
    $condition: ModelDispatchConditionInput
  ) {
    createDispatch(input: $input, condition: $condition) {
      id
      batteryOutNumber
      bike
      riderName
      riderPhoneNumber
      operatorName
      platform
      franchise
      status
      reference
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateDispatch = /* GraphQL */ `
  mutation UpdateDispatch(
    $input: UpdateDispatchInput!
    $condition: ModelDispatchConditionInput
  ) {
    updateDispatch(input: $input, condition: $condition) {
      id
      batteryOutNumber
      bike
      riderName
      riderPhoneNumber
      operatorName
      platform
      franchise
      status
      reference
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteDispatch = /* GraphQL */ `
  mutation DeleteDispatch(
    $input: DeleteDispatchInput!
    $condition: ModelDispatchConditionInput
  ) {
    deleteDispatch(input: $input, condition: $condition) {
      id
      batteryOutNumber
      bike
      riderName
      riderPhoneNumber
      operatorName
      platform
      franchise
      status
      reference
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createSwap = /* GraphQL */ `
  mutation CreateSwap(
    $input: CreateSwapInput!
    $condition: ModelSwapConditionInput
  ) {
    createSwap(input: $input, condition: $condition) {
      id
      country
      batteryInNumber
      batteryOutNumber
      bike
      riderName
      riderPhoneNumber
      numberOfOrders
      operatorName
      platform
      franchise
      amount
      status
      reference
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateSwap = /* GraphQL */ `
  mutation UpdateSwap(
    $input: UpdateSwapInput!
    $condition: ModelSwapConditionInput
  ) {
    updateSwap(input: $input, condition: $condition) {
      id
      country
      batteryInNumber
      batteryOutNumber
      bike
      riderName
      riderPhoneNumber
      numberOfOrders
      operatorName
      platform
      franchise
      amount
      status
      reference
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteSwap = /* GraphQL */ `
  mutation DeleteSwap(
    $input: DeleteSwapInput!
    $condition: ModelSwapConditionInput
  ) {
    deleteSwap(input: $input, condition: $condition) {
      id
      country
      batteryInNumber
      batteryOutNumber
      bike
      riderName
      riderPhoneNumber
      numberOfOrders
      operatorName
      platform
      franchise
      amount
      status
      reference
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createDelivery = /* GraphQL */ `
  mutation CreateDelivery(
    $input: CreateDeliveryInput!
    $condition: ModelDeliveryConditionInput
  ) {
    createDelivery(input: $input, condition: $condition) {
      id
      userID
      deliveryTime
      scheduledTime
      recipientName
      recipientPhoneNumber
      instructionsToRider
      vehicleType
      deliveryFee
      estimatedDeliveryTime
      paymentMethod
      origin
      destination
      originLatitude
      originLongitude
      destinationLatitude
      destinationLongitude
      deliveryRiderId
      rider {
        id
        name
        sub
        lat
        lng
        email
        status
        phone_number
        vehicleType
        nationalIDNumber
        fleet
        franchise
        createdAt
        updatedAt
        owner
        __typename
      }
      pin
      status
      reason
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateDelivery = /* GraphQL */ `
  mutation UpdateDelivery(
    $input: UpdateDeliveryInput!
    $condition: ModelDeliveryConditionInput
  ) {
    updateDelivery(input: $input, condition: $condition) {
      id
      userID
      deliveryTime
      scheduledTime
      recipientName
      recipientPhoneNumber
      instructionsToRider
      vehicleType
      deliveryFee
      estimatedDeliveryTime
      paymentMethod
      origin
      destination
      originLatitude
      originLongitude
      destinationLatitude
      destinationLongitude
      deliveryRiderId
      rider {
        id
        name
        sub
        lat
        lng
        email
        status
        phone_number
        vehicleType
        nationalIDNumber
        fleet
        franchise
        createdAt
        updatedAt
        owner
        __typename
      }
      pin
      status
      reason
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteDelivery = /* GraphQL */ `
  mutation DeleteDelivery(
    $input: DeleteDeliveryInput!
    $condition: ModelDeliveryConditionInput
  ) {
    deleteDelivery(input: $input, condition: $condition) {
      id
      userID
      deliveryTime
      scheduledTime
      recipientName
      recipientPhoneNumber
      instructionsToRider
      vehicleType
      deliveryFee
      estimatedDeliveryTime
      paymentMethod
      origin
      destination
      originLatitude
      originLongitude
      destinationLatitude
      destinationLongitude
      deliveryRiderId
      rider {
        id
        name
        sub
        lat
        lng
        email
        status
        phone_number
        vehicleType
        nationalIDNumber
        fleet
        franchise
        createdAt
        updatedAt
        owner
        __typename
      }
      pin
      status
      reason
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createLocation = /* GraphQL */ `
  mutation CreateLocation(
    $input: CreateLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    createLocation(input: $input, condition: $condition) {
      id
      userID
      latitude
      longitude
      address
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateLocation = /* GraphQL */ `
  mutation UpdateLocation(
    $input: UpdateLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    updateLocation(input: $input, condition: $condition) {
      id
      userID
      latitude
      longitude
      address
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteLocation = /* GraphQL */ `
  mutation DeleteLocation(
    $input: DeleteLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    deleteLocation(input: $input, condition: $condition) {
      id
      userID
      latitude
      longitude
      address
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createPayment = /* GraphQL */ `
  mutation CreatePayment(
    $input: CreatePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    createPayment(input: $input, condition: $condition) {
      id
      name
      phone_number
      amount
      paymentMethod
      platform
      status
      details
      reference
      requestId
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updatePayment = /* GraphQL */ `
  mutation UpdatePayment(
    $input: UpdatePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    updatePayment(input: $input, condition: $condition) {
      id
      name
      phone_number
      amount
      paymentMethod
      platform
      status
      details
      reference
      requestId
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deletePayment = /* GraphQL */ `
  mutation DeletePayment(
    $input: DeletePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    deletePayment(input: $input, condition: $condition) {
      id
      name
      phone_number
      amount
      paymentMethod
      platform
      status
      details
      reference
      requestId
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createDocument = /* GraphQL */ `
  mutation CreateDocument(
    $input: CreateDocumentInput!
    $condition: ModelDocumentConditionInput
  ) {
    createDocument(input: $input, condition: $condition) {
      id
      url
      sent
      name
      type
      createdAt
      updatedAt
      riders {
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const updateDocument = /* GraphQL */ `
  mutation UpdateDocument(
    $input: UpdateDocumentInput!
    $condition: ModelDocumentConditionInput
  ) {
    updateDocument(input: $input, condition: $condition) {
      id
      url
      sent
      name
      type
      createdAt
      updatedAt
      riders {
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const deleteDocument = /* GraphQL */ `
  mutation DeleteDocument(
    $input: DeleteDocumentInput!
    $condition: ModelDocumentConditionInput
  ) {
    deleteDocument(input: $input, condition: $condition) {
      id
      url
      sent
      name
      type
      createdAt
      updatedAt
      riders {
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const createRiderDocuments = /* GraphQL */ `
  mutation CreateRiderDocuments(
    $input: CreateRiderDocumentsInput!
    $condition: ModelRiderDocumentsConditionInput
  ) {
    createRiderDocuments(input: $input, condition: $condition) {
      id
      riderId
      documentId
      rider {
        id
        name
        sub
        lat
        lng
        email
        status
        phone_number
        vehicleType
        nationalIDNumber
        fleet
        franchise
        createdAt
        updatedAt
        owner
        __typename
      }
      document {
        id
        url
        sent
        name
        type
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateRiderDocuments = /* GraphQL */ `
  mutation UpdateRiderDocuments(
    $input: UpdateRiderDocumentsInput!
    $condition: ModelRiderDocumentsConditionInput
  ) {
    updateRiderDocuments(input: $input, condition: $condition) {
      id
      riderId
      documentId
      rider {
        id
        name
        sub
        lat
        lng
        email
        status
        phone_number
        vehicleType
        nationalIDNumber
        fleet
        franchise
        createdAt
        updatedAt
        owner
        __typename
      }
      document {
        id
        url
        sent
        name
        type
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteRiderDocuments = /* GraphQL */ `
  mutation DeleteRiderDocuments(
    $input: DeleteRiderDocumentsInput!
    $condition: ModelRiderDocumentsConditionInput
  ) {
    deleteRiderDocuments(input: $input, condition: $condition) {
      id
      riderId
      documentId
      rider {
        id
        name
        sub
        lat
        lng
        email
        status
        phone_number
        vehicleType
        nationalIDNumber
        fleet
        franchise
        createdAt
        updatedAt
        owner
        __typename
      }
      document {
        id
        url
        sent
        name
        type
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createProduct = /* GraphQL */ `
  mutation CreateProduct(
    $input: CreateProductInput!
    $condition: ModelProductConditionInput
  ) {
    createProduct(input: $input, condition: $condition) {
      id
      name
      description
      price
      unit
      imageUrl
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateProduct = /* GraphQL */ `
  mutation UpdateProduct(
    $input: UpdateProductInput!
    $condition: ModelProductConditionInput
  ) {
    updateProduct(input: $input, condition: $condition) {
      id
      name
      description
      price
      unit
      imageUrl
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteProduct = /* GraphQL */ `
  mutation DeleteProduct(
    $input: DeleteProductInput!
    $condition: ModelProductConditionInput
  ) {
    deleteProduct(input: $input, condition: $condition) {
      id
      name
      description
      price
      unit
      imageUrl
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
