/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:1fecaff3-5a3f-41cf-a4d4-9c06d58f01f2",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_deGWTQtee",
    "aws_user_pools_web_client_id": "26vigacat0k5ij97d3amh1pvk7",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL",
        "PHONE_NUMBER"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "NAME",
        "PHONE_NUMBER"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://crsunkrqkbebfdjjgpcooxl5fe.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-pti6bcm57bakpn5tppnxubbate",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "56cceedc939e481e9c4ebe05592dbdff",
            "region": "us-east-2"
        }
    }
};


export default awsmobile;
