import React, { useState, useEffect } from "react";
import { Card, Table, Tag, Button, Tooltip } from "antd";
import { listFleets, listRiders } from "../../../graphql/queries";
import { generateClient } from "aws-amplify/api";
import { useNavigate } from "react-router-dom";
import downloadExcel from "../../../components/download";
import { DownloadOutlined } from '@ant-design/icons';
import SearchComponent from "../../../components/searchBar";
import { fetchAuthSession, fetchUserAttributes } from 'aws-amplify/auth';
import AddFleetModal from "./addFleet";

const client = generateClient();

const Fleets = () => {
  const [fleets, setFleets] = useState([]);
  const [filteredFleets, setFilteredFleets] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const navigate = useNavigate();

  const renderRiderCount = (count) => {
    if (count === 0) {
      return <Tag color={"red"}>{count}</Tag>;
    }
    if (count >= 1) {
      return <Tag color={"green"}>{count}</Tag>;
    }
  };

  useEffect(() => {
    fetchFleets();
    checkUserRole();
  }, [client]);

  async function checkUserRole() {
    try {
      const session = await fetchAuthSession();
      const userAttributes = await fetchUserAttributes();

      const isAdminUser = userAttributes['custom:isAdmin'] === 'true';

      const groups = session.tokens.accessToken.payload['cognito:groups'];

      const isInAdminGroup = groups && groups.includes('Admins');
      const isInOperatorsGroup = groups && groups.includes('Operators');

      setIsAdmin(isAdminUser || isInAdminGroup);

    } catch (error) {
      console.error("Error checking user role:", error);
    }
  }

  async function fetchFleets() {
    try {
      const fleetData = await client.graphql({ query: listFleets });
      const ridersData = await client.graphql({ query: listRiders });

      const riders = ridersData.data.listRiders.items;

      const formattedFleets = fleetData.data.listFleets.items.map(fleet => {
      const fleetRiders = riders.filter(rider => rider.fleet === fleet.name);
        return {
          ...fleet,
          riderCount: fleetRiders.length
        };
      });

      setFleets(formattedFleets);
      setFilteredFleets(formattedFleets);
    } catch (error) {
      console.error("Error fetching fleets:", error);
    }
  }

  const handleSearch = (searchTerm) => {
    const filtered = fleets.filter(fleet =>
      fleet.location.toLowerCase().includes(searchTerm.toLowerCase()) ||
      fleet.batteryCount.toString().includes(searchTerm)
    );
    setFilteredFleets(filtered);
  };

  const handleFleetAdded = () => {
    fetchFleets(); // Refresh fleets after adding a new one
  };

  const tableColumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
    },
    {
        title: "Rider Count",
        dataIndex: "riderCount",
        key: "riderCount",
        render: renderRiderCount,
      },
  ];

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: 20,
          padding: 20,
        }}
      >
        <h2>Fleets</h2>
        <SearchComponent
          placeholder="Search by location"
          onSearch={handleSearch}
        />
        {isAdmin && (
          <Button type="primary" onClick={() => setIsModalVisible(true)}>
            Add Fleet
          </Button>
        )}
      </div>
      <Card title="Fleets List" extra={isAdmin && (
        <Tooltip title="Download as Excel">
          <DownloadOutlined onClick={() => downloadExcel(filteredFleets, "fleets")}
            style={{ fontSize: '20px', cursor: 'pointer' }}
          />
        </Tooltip>)}
        style={{ margin: 20 }}>
        <Table dataSource={filteredFleets} columns={tableColumns} rowKey="id"
          onRow={(requestItem) => ({
            onClick: () => {
              navigate(`fleet/${requestItem.id}`);
            },
          })} />
      </Card>
      <AddFleetModal
        visible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        onFleetAdded={handleFleetAdded}
      />
    </div>

  );
};

export default Fleets;
