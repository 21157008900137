import React, { useState } from "react";
import { Modal, Input, Form, Button } from "antd";
import { generateClient } from "aws-amplify/api";
import { createFleet } from "../../../../graphql/mutations"; // Ensure this mutation exists
import { toast } from "react-toastify";

const client = generateClient();

const AddFleetModal = ({ visible, onClose, onFleetAdded }) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const handleAddFleet = async () => {
    try {
      setLoading(true);
      const values = await form.validateFields();
      
      await client.graphql({
        query: createFleet,
        variables: { input: values },
      });

      toast.success("Fleet added successfully");
      form.resetFields();
      onFleetAdded();
      onClose();
    } catch (error) {
      console.error("Error adding fleet:", error);
      toast.error("Failed to add fleet");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title="Add Fleet"
      open={visible}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={loading} onClick={handleAddFleet}>
          Add Fleet
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical">
        <Form.Item label="Name" name="name" rules={[{ required: true, message: "Please enter fleet name" }]}>
          <Input placeholder="Enter fleet name" />
        </Form.Item>
        <Form.Item label="Country" name="country" rules={[{ required: true, message: "Please enter country" }]}>
          <Input placeholder="Enter country" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddFleetModal;
