import React, { useState, useEffect } from "react";
import { Card, Table, Tag, Button, Tooltip, DatePicker, Space, Modal } from "antd";
import { listDispatches } from "../../../graphql/queries";
import { createDispatch } from "../../../graphql/mutations";
import { generateClient } from "aws-amplify/api";
import { useNavigate } from "react-router-dom";
import downloadExcel from "../../../components/download";
import { DownloadOutlined } from '@ant-design/icons';
import { toast } from "react-toastify";
import SearchComponent from "../../../components/searchBar";
import { fetchAuthSession, fetchUserAttributes } from 'aws-amplify/auth';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import ExcelUploader from "../../../components/upload";
import * as XLSX from "xlsx";

dayjs.extend(utc);
dayjs.extend(timezone);

const client = generateClient();
const { RangePicker } = DatePicker;

const Dispatch = () => {
  const [dispatches, setDispatches] = useState([]);
  const [filteredDispatches, setFilteredDispatches] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [dateRange, setDateRange] = useState([null, null]);
  const [searchTerm, setSearchTerm] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploadModalVisible, setIsUploadModalVisible] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [nextToken, setNextToken] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const renderDispatchStatus = (requestStatus) => {
    if (requestStatus === "success") {
      return <Tag color={"green"}>{requestStatus}</Tag>;
    }
    if (requestStatus === "pending") {
      return <Tag color={"orange"}>{requestStatus}</Tag>;
    }
    if (requestStatus === "failed") {
      return <Tag color={"red"}>{requestStatus}</Tag>;
    }
  };

  useEffect(() => {
    fetchDispatches();
    checkUserRole();
  }, []);

  useEffect(() => {
    filterDispatches();
  }, [dispatches, searchTerm, dateRange]);

  async function checkUserRole() {
    try {
      const session = await fetchAuthSession();
      const userAttributes = await fetchUserAttributes();

      const isAdminUser = userAttributes['custom:isAdmin'] === 'true';

      const groups = session.tokens.accessToken.payload['cognito:groups'];

      const isInAdminGroup = groups && groups.includes('Admins');

      setIsAdmin(isAdminUser || isInAdminGroup);

    } catch (error) {
      console.error("Error checking user role:", error);
    }
  }

  async function fetchDispatches() {
    let allDispatches = [];
    let nextToken = null;
    try {
      do {
        const dispatchData = await client.graphql({
          query: listDispatches,
          variables: { limit: 1000, nextToken: nextToken }
        });
        allDispatches = [...allDispatches, ...dispatchData.data.listDispatches.items];
        nextToken = dispatchData.data.listDispatches.nextToken;
      } while (nextToken);

      const formattedDispatches = allDispatches.map(dispatch => ({
        ...dispatch,
        createdAt: dayjs(dispatch.createdAt).tz('Africa/Nairobi').format('YYYY-MM-DD HH:mm:ss')
      }));

      const sortedDispatches = formattedDispatches.sort((a, b) =>
        dayjs(b.createdAt).valueOf() - dayjs(a.createdAt).valueOf()
      );

      setDispatches(sortedDispatches);
      setFilteredDispatches(sortedDispatches);
    } catch (error) {
      console.error("Error fetching dispatches:", error);
    }
  }

  const handleSearch = (term) => {
    setSearchTerm(term);
  };

  const handleDateRangeChange = (dates) => {
    setDateRange(dates);
  };

  const filterDispatches = () => {
    let filtered = [...dispatches];

    if (searchTerm) {
      filtered = filtered.filter(dispatch =>
        (dispatch.batteryInNumber && dispatch.batteryInNumber.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (dispatch.batteryOutNumber && dispatch.batteryOutNumber.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (dispatch.franchise && dispatch.franchise.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (dispatch.riderName && dispatch.riderName.toLowerCase().includes(searchTerm.toLowerCase()))
      );
    }

    if (dateRange && dateRange[0] && dateRange[1]) {
      filtered = filtered.filter(dispatch => {
        const dispatchDate = dayjs(dispatch.createdAt);
        return dispatchDate.isAfter(dateRange[0].startOf('day')) && dispatchDate.isBefore(dateRange[1].endOf('day'));
      });
    }

    setFilteredDispatches(filtered);
  };

  const handleFileUpload = (file) => {

    setIsProcessing(true);
    const reader = new FileReader();
    reader.onload = async (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const json = XLSX.utils.sheet_to_json(worksheet);

      console.log(`Total dispatches in Excel: ${json.length}`);

      if (json.length === 0) {
        toast.error("The Excel file is empty or could not be parsed.");
        setIsProcessing(false);
        return;
      }

      const requiredColumns = ['Battery Out Number', 'Bike Type', 'Rider Name', 'Phone number', 'Operator Name', 'Platform', 'Franchise'];
      const headers = Object.keys(json[0]);

      const headerMap = {};
      requiredColumns.forEach(required => {
        const match = headers.find(header =>
          header.toLowerCase().trim() === required.toLowerCase().trim()
        );
        if (match) {
          headerMap[required] = match;
        }
      });

      const missingColumns = requiredColumns.filter(col => !headerMap[col]);

      if (missingColumns.length > 0) {
        toast.error(`Missing required columns: ${missingColumns.join(', ')}`);
        setIsProcessing(false);
        return;
      }

      try {
        let processedCount = 0;
        let errorCount = 0;

        for (let i = 0; i < json.length; i++) {
          const row = json[i];

          // Ensure all string values are properly formatted and not null
          const dispatchData = {
            batteryOutNumber: String(row[headerMap['Battery Out Number']] || '').trim(),
            bike: String(row[headerMap['Bike Type']] || '').trim(),
            riderName: String(row[headerMap['Rider Name']] || '').trim(),
            riderPhoneNumber: String(row[headerMap['Phone number']] || '').trim(),
            operatorName: String(row[headerMap['Operator Name']] || '').trim(),
            platform: String(row[headerMap['Platform']] || '').trim(),
            franchise: String(row[headerMap['Franchise']] || '').trim(),
            status: "success",
            reference: `FREE-DISPATCH-${Date.now()}-${i}`, // Added index to make reference unique
          };

          // Validate required fields
          if (!dispatchData.batteryOutNumber ||
            !dispatchData.riderName ||
            !dispatchData.riderPhoneNumber ||
            !dispatchData.bike ||
            !dispatchData.operatorName ||
            !dispatchData.platform ||
            !dispatchData.franchise) {
            console.error(`Row ${i + 1}: Missing required fields:`, dispatchData);
            errorCount++;
            continue;
          }

          try {
            // Create dispatch without checking for duplicates
            await client.graphql({
              query: createDispatch,
              variables: {
                input: dispatchData
              },
            });
            processedCount++;
            console.log(`Row ${i + 1}: Dispatch added successfully:`, dispatchData);
          } catch (error) {
            console.error(`Row ${i + 1}: Error processing dispatch:`, error);
            errorCount++;
          }

          setUploadProgress(Math.round(((i + 1) / json.length) * 100));
        }

        console.log(`Upload Summary:
                    Total in Excel: ${json.length}
                    Processed: ${processedCount}
                    Errors: ${errorCount}`
        );

        toast.success(`Dispatches uploaded. 
                    Added: ${processedCount}, 
                    Errors: ${errorCount}`
        );
        setIsUploadModalVisible(false);
        setUploadedFile(null);
        fetchDispatches();
      } catch (error) {
        console.error("Error uploading dispatches:", error);
        toast.error("An error occurred while uploading dispatches");
      } finally {
        setIsProcessing(false);
        setUploadProgress(0);
      }
    };
    reader.readAsArrayBuffer(file);
  };
  const tableColumns = [
    {
      title: "Battery Out",
      dataIndex: "batteryOutNumber",
      key: "batteryOutNumber",
    },
    {
      title: "Bike Type",
      dataIndex: "bike",
      key: "bike",
    },
    {
      title: "Rider",
      dataIndex: "riderName",
      key: "riderName",
    },
    {
      title: "Phone Number",
      dataIndex: "riderPhoneNumber",
      key: "riderPhoneNumber",
    },
    {
      title: "Franchise",
      dataIndex: "franchise",
      key: "franchise",
    },
    {
      title: "Platform",
      dataIndex: "platform",
      key: "platform",
    },
    {
      title: "Time Dispatched",
      dataIndex: "createdAt",
      key: "createdAt",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: renderDispatchStatus,
    },
  ];

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: 20,
          padding: 20,
        }}
      >
        <h2>Dispatches</h2>
        <SearchComponent
          placeholder="Search by battery number"
          onSearch={handleSearch}
        />
        <div
          style={{
            display: "flex",
            gap: 20,
          }}>
          <Button type="primary" onClick={() => setIsUploadModalVisible(true)}>
            Bulk Upload
          </Button>
          <Button type="primary" onClick={() => navigate("/add-dispatch")}>
            Add Dispatch
          </Button>
        </div>
      </div>
      <Card title="Dispatch List" extra={
        <Space>
          <RangePicker onChange={handleDateRangeChange} />
          {isAdmin && (
            <Tooltip title="Download as Excel">
              <DownloadOutlined
                onClick={() => downloadExcel(filteredDispatches, "dispatches")}
                style={{ fontSize: '20px', cursor: 'pointer' }}
              />
            </Tooltip>
          )}
        </Space>
      }
        style={{ margin: 20 }}
      >
        <Table dataSource={filteredDispatches} columns={tableColumns} rowKey="id"
          onRow={(requestItem) => ({
            onClick: () => {
              navigate(`/Dispatch/${requestItem.id}`);
            },
          })} />
      </Card>
      <Modal
        title="Upload Dispatches"
        open={isUploadModalVisible}
        onCancel={() => {
          setIsUploadModalVisible(false)
          setUploadedFile(null)
        }}
        footer={null}
        centered
      >
        <ExcelUploader
          onUpload={handleFileUpload}
          uploadProgress={uploadProgress}
        />
        <div style={{ display: "flex", justifyContent: "center", marginTop: 20 }}>
          <Button type="primary"
            onClick={handleFileUpload}
            loading={isProcessing}
            disabled={!uploadedFile || isProcessing}
          >
            Add Dispatches
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default Dispatch;